// 获取列表
const getListURL = `${API_CONFIG.controlBaseURL}volunteer/dutyList`
// 获取所属区域
const getRegionsURL = `${API_CONFIG.controlBaseURL}region/select2`
// 获取服务大类
const getTypeIdURL = `${API_CONFIG.baseURL}serverCodewordAction!getVolunteerTypes.action`
// 获取项目列表
const getCommunityListURL = `${API_CONFIG.controlBaseURL}community/getCommunityList?ismodal=1`
// 获取运营项目组
const getOperateRegionsURL = `${API_CONFIG.baseURL}serverCodewordAction!getOperateRegions.action`
// 获取阶段状态
const getProjectTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getProjectTypes.action`
// 项目类型
const getRoomTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getRoomTypes.action`

// 新增
const createURL = `${API_CONFIG.baseURL}serverSelfRulesAction!saveDuty.action`
// 编辑
const editURL = `${API_CONFIG.baseURL}serverSelfRulesAction!updateDuty.action`
// 查询详情
const queryURL = `${API_CONFIG.baseURL}serverSelfRulesAction!queryDuty.action`
const getCategoryListURL = `${API_CONFIG.controlBaseURL}volunteer/categoryList`
const categoryDeleteURL = `${API_CONFIG.controlBaseURL}volunteer/categoryDelete`
const categoryAddURL = `${API_CONFIG.controlBaseURL}volunteer/categoryAdd`
const categoryUpdateURL = `${API_CONFIG.controlBaseURL}volunteer/categoryUpdate`
const categoryDetailURL = `${API_CONFIG.controlBaseURL}volunteer/categoryDetail`
export {
  getListURL,
  getRegionsURL,
  getTypeIdURL,
  getCommunityListURL,
  getOperateRegionsURL,
  getProjectTypesURL,
  getRoomTypesURL,
  createURL,
  editURL,
  queryURL,
  getCategoryListURL,
  categoryDeleteURL,
  categoryAddURL,
  categoryUpdateURL,
  categoryDetailURL
}
