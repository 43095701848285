<template>
  <div class="DutyForm-wrapper">
    <form-panel ref="formPanel" v-bind="submitConfig" :form="form" :submitBefore="submitBefore" @update="update">
      <col2-detail v-if="!look">
        <col2-block title="志愿者岗位信息">
          <el-form-item label="岗位名称" :rules="[{ required: true, message: '请输入岗位名称', trigger: 'blur' }]"
            prop="volunteerDuty">
            <v-input v-model="form.volunteerDuty" placeholder="请输入岗位名称" :width="width" />
          </el-form-item>
          <el-form-item label="服务大类">
            <v-select v-model="form.volunteerCat" :options="volunteerCatOps" :width="width">
              <template v-slot="slotProps">
                <span style="float: left">{{ slotProps.optionData.text }}</span>
                <v-button permission="editClassify" text="" type="text" style="float: right;"
                  @click.stop="editOption(slotProps.optionData)"><i class="el-icon-edit"></i></v-button>
              </template>
            </v-select>
            <v-button text="新增分类" @click="addVolunteerCat" style="margin-left:20px;" permission="addClassify"></v-button>
            <!-- <v-button text="新增分类" @click="addVolunteerCat" style="margin-left:20px;" ></v-button> -->
          </el-form-item>
          <el-form-item label="岗位说明" prop="introduce"
            :rules="[{ required: true, message: '岗位说明不允许为空', trigger: 'blur' }]">
            <v-textarea v-model="form.introduce" :maxlength="200"></v-textarea>
            <div>内容请简洁突出</div>
          </el-form-item>
          <el-form-item v-if="$route.query.id" label="发布项目" :rules="[{ required: true }]">
            <span>{{ communityName }}</span>
          </el-form-item>
          <el-form-item v-if="isShowCommunityIdsList && !$route.query.id" label="发布项目" prop="communityIds"
            :rules="[{ required: true, validator: communityIdsVaild, trigger: 'change1' }]">
            <chosenListPanel :list.sync="communityIdsList" @select="toSelectCommunity"></chosenListPanel>
          </el-form-item>
          <el-form-item label="状态">
            <v-select v-model="form.isExamine" :options="statusOps" :width="width" />
          </el-form-item>
        </col2-block>
      </col2-detail>
      <col2-detail v-if="look">
        <col2-block title="志愿者岗位信息">
          <el-form-item label="岗位名称">
            <span>{{ form.volunteerDuty }}</span>
          </el-form-item>
          <el-form-item label="服务大类">
            <span>{{ categoryName }}</span>
          </el-form-item>
          <el-form-item label="岗位说明">
            <span>{{ form.introduce }}</span>
          </el-form-item>
          <el-form-item label="发布项目">
            <span>{{ communityName }}</span>
          </el-form-item>
          <el-form-item label="状态">
            <span>{{ statusMap[form.isExamine] }}</span>
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
    <multi-select title="项目列表" :isShow.sync="isShow" :searchUrl="getCommunityListURL" :headers="communityTableHeader"
      :searchParams="searchParams" :responseParams="responseParams" :responseKey="responseKey"
      :backFill.sync="communityIdsList" :handleData="handleData">
      <template #searchSlot>
        <v-input label="项目名称" v-model="searchParams.communityName"></v-input>
        <v-select label="区域类型" v-model="searchParams.searchRegionType" :options="regionTypeOps"></v-select>
        <v-select2 label="所属区域" v-model="searchParams.regionId" v-bind="regionParams"
          :subjoin="{ regionType: searchParams.searchRegionType }"></v-select2>
        <v-input label="所在省" v-model="searchParams.province"></v-input>
        <v-input label="所在城市" v-model="searchParams.city"></v-input>
        <v-input label="所在区" v-model="searchParams.area"></v-input>
        <v-select label="项目阶段状态" v-model="searchParams.communityStage" :options="communityStageOps"></v-select>
        <v-select label="项目类型" v-model="searchParams.communityType" :options="communityTypeOps"></v-select>
        <v-select label="项目状态" v-model="searchParams.communityStatus" :options="multiStatusOps"></v-select>
      </template>
    </multi-select>
    <el-dialog :title="type == 1 ? '新增' : '编辑'" :visible.sync="auditVisible" width="400px" :modal-append-to-body="false">
      <el-form class="audit-from-wrapper" label-width="110px" :model="auditForm" ref="auditForm">
        <el-form-item label="服务大类名称" prop="categoryName"
          :rules="[{ required: true, message: '请填写分类名称', trigger: 'blur' }]">
          <v-input v-model="auditForm.categoryName" :maxlength="4" :width="width1"></v-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort" :rules="[{ required: true, message: '请排序', trigger: 'blur' }]"> 
          <el-input-number v-model="auditForm.sort"  :min="1" controls-position="right"   style="width:120px" ></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <v-button @click="auditVisible = false" style="margin-right:10px" text="取 消" v-if="type == 1"></v-button>
        <v-button @click="deleteOption" style="margin-right:10px" text="删 除" v-else></v-button>
        <v-button type="primary" @click="auditConfirm" text="确 定"></v-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { createURL, editURL, queryURL, getCommunityListURL, getRegionsURL, getProjectTypesURL, getRoomTypesURL, getCategoryListURL, categoryDeleteURL, categoryAddURL, categoryUpdateURL, categoryDetailURL } from './api'
import { setStatusOps, statusMap, regionTypeOps, multiStatusOps, multiStatusMap } from './map'
import { Col2Detail, Col2Block, MultiSelect, ChosenListPanel } from 'components/bussiness'

export default {
  name: 'DutyForm',
  components: {
    Col2Detail,
    Col2Block,
    MultiSelect,
    ChosenListPanel
  },
  data () {
    return {
      width: 182,
      width1: 200,
      statusOps: setStatusOps(2),
      statusMap,
      multiStatusOps: multiStatusOps(1),
      communityName: '',
      isShow: false,
      isShowCommunityIdsList: true,
      regionTypeOps,
      volunteerCatOps: [],
      communityIdsList: [],
      communityStageOps: [],
      communityTypeOps: [],
      getCommunityListURL: getCommunityListURL,
      regionParams: {
        searchUrl: getRegionsURL,
        request: {
          text: 'regionName',
          value: 'regionId'
        }
      },
      communityTableHeader: [
        {
          prop: 'communityName',
          label: '项目名称'
        },
        {
          prop: 'communityStatusText',
          label: '项目状态'
        },
        {
          prop: 'communityStage',
          label: '项目阶段'
        },
        {
          prop: 'provinceName',
          label: '所在省'
        },
        {
          prop: 'cityName',
          label: '所在市'
        },
        {
          prop: 'countryName',
          label: '所在区'
        },
        {
          prop: 'communityPhone',
          label: '项目电话'
        }
      ],
      searchParams: {
        communityName: '',
        searchRegionType: undefined,
        regionId: '',
        province: '',
        city: '',
        area: '',
        communityStage: undefined,
        communityType: undefined,
        communityStatus: 0
      },
      responseParams: {
        id: 'id',
        name: 'communityName'
      },
      responseKey: {
        id: 'id',
        name: 'text'
      },
      form: {
        volunteerDuty: '',
        volunteerCat: undefined,
        introduce: '',
        isExamine: 1
      },
      submitConfig: {
        queryUrl: queryURL,
        submitUrl: '',
        submitContentType: 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      auditVisible: false,
      auditForm:{categoryName:'dsds',sort:0},
      type: 1, //1新增2编辑
      id: '',
      tenantId: '',
      communityId: '',
      categoryName: '',
      look: ''
    }
  },
  created () {
    let userInfo = this.$store.state.userInfo
    console.log(this.$store.state)
    if (userInfo.userType === '101') {
      //项目
      this.communityId = userInfo.communityId
      this.isShowCommunityIdsList = false
    } else if (userInfo.userType === '102') {
      this.tenantId = userInfo.orgId
    }
    this.getTypeIdData()
  },
  mounted () {
    const { id, look } = this.$route.query
    if (id !== undefined) {
      this.look = look
      if (look) {
        this.$setBreadcrumb('查看')
      } else {
        this.$setBreadcrumb('编辑')
        this.submitConfig.submitUrl = editURL
      }
    } else {
      this.$setBreadcrumb('新增')
      this.submitConfig.submitUrl = createURL
    }
    this.getProjectTypes()
    this.getRoomTypes()
  },
  methods: {
    toSelectCommunity () {
      this.isShow = true
    },
    handleData (data) {
      data.communityStatusText = multiStatusMap[data.communityStatus]
    },
    communityIdsVaild (rule, value, callback) {
      if (this.ruleId) {
        callback()
      } else {
        if (this.communityIdsList.length > 0) {
          callback()
        } else {
          callback(new Error('请选择发布项目'))
        }
      }
    },
    getProjectTypes () {
      this.$axios.get(getProjectTypesURL).then(res => {
        if (res.status === '100') {
          let { data } = res
          let ops = data.map((val) => {
            return {
              text: val.value,
              value: val.id
            }
          })
          this.communityStageOps = [{
            text: '全部',
            value: undefined
          },
          ...ops]
        }
      })
    },
    getRoomTypes () {
      this.$axios.get(getRoomTypesURL).then(res => {
        if (res.status === '100') {
          let { data } = res
          let ops = data.map((val) => {
            return {
              text: val.value,
              value: val.id
            }
          })
          this.communityTypeOps = [{
            text: '全部',
            value: undefined
          },
          ...ops]
        }
      })
    },
    getTypeIdData () {
      const _this = this
      let params = ''
      let userInfo = this.$store.state.userInfo
      if (userInfo.userType === '101') {
        //项目
        params = `?communityId=${this.communityId}`
      } else if (userInfo.userType === '102') {
        params = `?tenantId=${this.tenantId}`
      }
      this.$axios.get(getCategoryListURL + params).then(res => {
        if (res.status == '100') {
          const data = res.data 
          if (data.length) {
            data.forEach(item => {
              _this.volunteerCatOps.push({
                text: item.categoryName,
                value: item.id,
                sort:item.sort
              })
            })
            if (!_this.form.volunteerCat) {
              _this.form.volunteerCat = _this.volunteerCatOps[0].value
            }
          }
          // 防止详情数据先请求导致页面显示id，等数据字典有值再请求详情
          const { id } = this.$route.query
          if (id !== undefined) {
            this.$refs.formPanel.getData({ id })
          }
        }
      })
    },
    update (data) {
      this.form.jobId = data.jobId
      this.communityName = data.communityName
      Object.keys(this.form).forEach(key => {
        this.form[key] = data[key]
      })
      this.getVolunteerCatName()
    },
    submitBefore (data) {
      data.communityId = JSON.stringify(this.communityIdsList.map(item => item.id))
      data.dataObject = data.volunteerDuty
      return true
    },
    addVolunteerCat () {
      this.type = 1
      this.$refs.auditForm && this.$refs.auditForm.clearValidate()
      this.auditForm.categoryName = ''
      this.auditForm.sort=0
      this.auditVisible = true
    },
    editOption (option) {
      this.type = 2
      this.$refs.auditForm && this.$refs.auditForm.clearValidate()
      this.id = option.value
      this.auditForm.categoryName = option.text
      this.auditForm.sort=option.sort 
      this.auditVisible = true
    },
    async deleteOption () {
      this.$axios({
        url: categoryDeleteURL + `?id=${this.id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          if (res.status === 100) {
            if (this.form.volunteerCat == this.id) {
              this.form.volunteerCat = undefined
            }
            this.volunteerCatOps = []
            this.getTypeIdData()
            this.$message({
              type: 'success',
              message: '操作成功',
              center: true
            })
            this.auditVisible = false
          }
        })
    },
    auditConfirm () {
      this.$refs.auditForm.validate((valid) => {
        if (valid) {
          if (this.type == 1) {
            //新增
            let params = {
              tenantId: this.tenantId, //志愿者大类id
              categoryName: this.auditForm.categoryName,//大类名称
              sort:this.auditForm.sort//大类排序
            }
            this.$axios.post(categoryAddURL, params).then((res) => {
              if (String(res.status) == '100') {
                this.volunteerCatOps = []
                this.getTypeIdData()
                this.$message({
                  type: 'success',
                  message: '操作成功',
                  center: true
                })
                this.auditVisible = false
              }
            })
          } else {
            //更新
            let params = {
              id: this.id, //志愿者大类id
              categoryName: this.auditForm.categoryName,//大类名称
              sort:this.auditForm.sort//大类排序
            }
            this.$axios.post(categoryUpdateURL, params).then((res) => {
              if (String(res.status) == '100') {
                this.volunteerCatOps = []
                this.getTypeIdData()
                this.$message({
                  type: 'success',
                  message: '操作成功',
                  center: true
                })
                this.auditVisible = false
              }
            })
          }
        }
      })
    },
    getVolunteerCatName () {
      this.$axios({
        url: categoryDetailURL + `?id=${this.form.volunteerCat}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          if (res.status === 100) {
            this.categoryName = res.data.categoryName
          }
        })
    }
  }
}
</script>
<style lang="scss" scope>
.el-form-item__content {
  text-align: left;
}

.DutyForm-wrapper {
  .el-dialog__wrapper {
    z-index: 3002 !important;
  }

 /* .v-modal{
    z-index: 3001 !important;
  }*/
}
</style>
