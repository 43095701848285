import {
  mapHelper
} from 'common/utils'

// 状态
const status = [{
  text: '正常',
  value: 1
},
{
  text: '关闭',
  value: 0
}
]
const multiStatus = [{
  text: '正常',
  value: 0
},
{
  text: '关闭',
  value: 1
}
]

const {
  map: statusMap,
  setOps: setStatusOps
} = mapHelper.setMap(status)
const {
  map: multiStatusMap,
  setOps: multiStatusOps
} = mapHelper.setMap(multiStatus)
// 区域类型
const regionTypeOps = [{
  text: '全部',
  value: undefined
}, {
  text: '租户',
  value: 0
}, {
  text: '园区公司分公司',
  value: 1
}, {
  text: '管理项目组',
  value: 2
}]

export {
  statusMap,
  setStatusOps,
  regionTypeOps,
  multiStatusMap,
  multiStatusOps
}
