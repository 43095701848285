var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "DutyForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          !_vm.look
            ? _c(
                "col2-detail",
                [
                  _c(
                    "col2-block",
                    { attrs: { title: "志愿者岗位信息" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "岗位名称",
                            rules: [
                              {
                                required: true,
                                message: "请输入岗位名称",
                                trigger: "blur",
                              },
                            ],
                            prop: "volunteerDuty",
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入岗位名称",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.volunteerDuty,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "volunteerDuty", $$v)
                              },
                              expression: "form.volunteerDuty",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "服务大类" } },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.volunteerCatOps,
                              width: _vm.width,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (slotProps) {
                                    return [
                                      _c(
                                        "span",
                                        { staticStyle: { float: "left" } },
                                        [
                                          _vm._v(
                                            _vm._s(slotProps.optionData.text)
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-button",
                                        {
                                          staticStyle: { float: "right" },
                                          attrs: {
                                            permission: "editClassify",
                                            text: "",
                                            type: "text",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.editOption(
                                                slotProps.optionData
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-edit",
                                          }),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1968266551
                            ),
                            model: {
                              value: _vm.form.volunteerCat,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "volunteerCat", $$v)
                              },
                              expression: "form.volunteerCat",
                            },
                          }),
                          _c("v-button", {
                            staticStyle: { "margin-left": "20px" },
                            attrs: {
                              text: "新增分类",
                              permission: "addClassify",
                            },
                            on: { click: _vm.addVolunteerCat },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "岗位说明",
                            prop: "introduce",
                            rules: [
                              {
                                required: true,
                                message: "岗位说明不允许为空",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-textarea", {
                            attrs: { maxlength: 200 },
                            model: {
                              value: _vm.form.introduce,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "introduce", $$v)
                              },
                              expression: "form.introduce",
                            },
                          }),
                          _c("div", [_vm._v("内容请简洁突出")]),
                        ],
                        1
                      ),
                      _vm.$route.query.id
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "发布项目",
                                rules: [{ required: true }],
                              },
                            },
                            [_c("span", [_vm._v(_vm._s(_vm.communityName))])]
                          )
                        : _vm._e(),
                      _vm.isShowCommunityIdsList && !_vm.$route.query.id
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "发布项目",
                                prop: "communityIds",
                                rules: [
                                  {
                                    required: true,
                                    validator: _vm.communityIdsVaild,
                                    trigger: "change1",
                                  },
                                ],
                              },
                            },
                            [
                              _c("chosenListPanel", {
                                attrs: { list: _vm.communityIdsList },
                                on: {
                                  "update:list": function ($event) {
                                    _vm.communityIdsList = $event
                                  },
                                  select: _vm.toSelectCommunity,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "状态" } },
                        [
                          _c("v-select", {
                            attrs: { options: _vm.statusOps, width: _vm.width },
                            model: {
                              value: _vm.form.isExamine,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "isExamine", $$v)
                              },
                              expression: "form.isExamine",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.look
            ? _c(
                "col2-detail",
                [
                  _c(
                    "col2-block",
                    { attrs: { title: "志愿者岗位信息" } },
                    [
                      _c("el-form-item", { attrs: { label: "岗位名称" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.form.volunteerDuty))]),
                      ]),
                      _c("el-form-item", { attrs: { label: "服务大类" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.categoryName))]),
                      ]),
                      _c("el-form-item", { attrs: { label: "岗位说明" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.form.introduce))]),
                      ]),
                      _c("el-form-item", { attrs: { label: "发布项目" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.communityName))]),
                      ]),
                      _c("el-form-item", { attrs: { label: "状态" } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.statusMap[_vm.form.isExamine])),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("multi-select", {
        attrs: {
          title: "项目列表",
          isShow: _vm.isShow,
          searchUrl: _vm.getCommunityListURL,
          headers: _vm.communityTableHeader,
          searchParams: _vm.searchParams,
          responseParams: _vm.responseParams,
          responseKey: _vm.responseKey,
          backFill: _vm.communityIdsList,
          handleData: _vm.handleData,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.isShow = $event
          },
          "update:is-show": function ($event) {
            _vm.isShow = $event
          },
          "update:backFill": function ($event) {
            _vm.communityIdsList = $event
          },
          "update:back-fill": function ($event) {
            _vm.communityIdsList = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "项目名称" },
                  model: {
                    value: _vm.searchParams.communityName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "communityName", $$v)
                    },
                    expression: "searchParams.communityName",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "区域类型", options: _vm.regionTypeOps },
                  model: {
                    value: _vm.searchParams.searchRegionType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "searchRegionType", $$v)
                    },
                    expression: "searchParams.searchRegionType",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: {
                        label: "所属区域",
                        subjoin: {
                          regionType: _vm.searchParams.searchRegionType,
                        },
                      },
                      model: {
                        value: _vm.searchParams.regionId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "regionId", $$v)
                        },
                        expression: "searchParams.regionId",
                      },
                    },
                    "v-select2",
                    _vm.regionParams,
                    false
                  )
                ),
                _c("v-input", {
                  attrs: { label: "所在省" },
                  model: {
                    value: _vm.searchParams.province,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "province", $$v)
                    },
                    expression: "searchParams.province",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "所在城市" },
                  model: {
                    value: _vm.searchParams.city,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "city", $$v)
                    },
                    expression: "searchParams.city",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "所在区" },
                  model: {
                    value: _vm.searchParams.area,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "area", $$v)
                    },
                    expression: "searchParams.area",
                  },
                }),
                _c("v-select", {
                  attrs: {
                    label: "项目阶段状态",
                    options: _vm.communityStageOps,
                  },
                  model: {
                    value: _vm.searchParams.communityStage,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "communityStage", $$v)
                    },
                    expression: "searchParams.communityStage",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "项目类型", options: _vm.communityTypeOps },
                  model: {
                    value: _vm.searchParams.communityType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "communityType", $$v)
                    },
                    expression: "searchParams.communityType",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "项目状态", options: _vm.multiStatusOps },
                  model: {
                    value: _vm.searchParams.communityStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "communityStatus", $$v)
                    },
                    expression: "searchParams.communityStatus",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.type == 1 ? "新增" : "编辑",
            visible: _vm.auditVisible,
            width: "400px",
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.auditVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "auditForm",
              staticClass: "audit-from-wrapper",
              attrs: { "label-width": "110px", model: _vm.auditForm },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "服务大类名称",
                    prop: "categoryName",
                    rules: [
                      {
                        required: true,
                        message: "请填写分类名称",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("v-input", {
                    attrs: { maxlength: 4, width: _vm.width1 },
                    model: {
                      value: _vm.auditForm.categoryName,
                      callback: function ($$v) {
                        _vm.$set(_vm.auditForm, "categoryName", $$v)
                      },
                      expression: "auditForm.categoryName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "排序",
                    prop: "sort",
                    rules: [
                      { required: true, message: "请排序", trigger: "blur" },
                    ],
                  },
                },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "120px" },
                    attrs: { min: 1, "controls-position": "right" },
                    model: {
                      value: _vm.auditForm.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.auditForm, "sort", $$v)
                      },
                      expression: "auditForm.sort",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.type == 1
                ? _c("v-button", {
                    staticStyle: { "margin-right": "10px" },
                    attrs: { text: "取 消" },
                    on: {
                      click: function ($event) {
                        _vm.auditVisible = false
                      },
                    },
                  })
                : _c("v-button", {
                    staticStyle: { "margin-right": "10px" },
                    attrs: { text: "删 除" },
                    on: { click: _vm.deleteOption },
                  }),
              _c("v-button", {
                attrs: { type: "primary", text: "确 定" },
                on: { click: _vm.auditConfirm },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }